import { useDispatch, useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import React, { useEffect, useState } from 'react';

import { capitalizeWords } from '../../../utils/strings';
import { typeDelivery } from '../../../constants/general';

import SwitchStateDeliveryCompany from '../switchStateDeliveryCompany';
import PrinFormatSettings from '../printFormatSettings';
import { updateDeliveryCompanyDisabledUser, getUsersAddresses } from '../../../services/user';
import { UpdateUserAction } from '../../../store/actions/auth.actions';

import HeaderButtonBack from '../../../components/headerButtonBack';
import Typography from '../../../components/typography';

import './myTransportersSettings.scss';

const MyTransportersSettings = () => {
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);
  const user = useSelector((state) => state.auth?.user || {});
  const deliveryCompanies = useSelector((state) => state.globalData.deliveryCompanies || []);
  const deliveryDisabled = useSelector((state) => state.globalData.deliveryDisabled || []);

  const dispatch = useDispatch();

  const [deliveryCompanyList, setDeliveryCompanyList] = useState(null);
  const [listAddress, setListAddress] = useState([]);
  const getAddressesService = async () => {
    const addressestoMap = await getUsersAddresses();
    setListAddress(addressestoMap);
  };

  useEffect(() => {
    getAddressesService();
  }, []);

  const listAddressEnabled = listAddress.filter((object) => object.enabled === true);
  const idDeliveryCompany = deliveryDisabled?.map((item) => (item.deliveryCompanyId)).join(',');

  useEffect(() => {
    const deliveryCompaniesActive = [];
    deliveryCompanies.forEach((e) => ((!e.inactive) ? deliveryCompaniesActive.push(e) : null));
    const listCompany = deliveryCompaniesActive.map((company) => {
      if (user.deliveryCompanyDisabled) {
        const userCompanyList = user.deliveryCompanyDisabled.filter(
          (companyDisabled) => companyDisabled.deliveryCompanyId === company._id,
        );
        return { ...company, disabled: (userCompanyList.length > 0) };
      }
      return company;
    });
    const countryFilter = listCompany.filter((company) => company.countryCode === user.countryCode);
    setDeliveryCompanyList(countryFilter);
  }, []);

  useEffect(async () => {
    if (deliveryCompanyList) {
      const updateDeliveryCompayDisabled = deliveryCompanyList.filter(
        (delivery) => delivery.disabled === true,
      ).map((listDelivery) => ({ deliveryCompanyId: listDelivery._id, disabled: true }));
      const updateDeliveryCompany = await updateDeliveryCompanyDisabledUser(
        updateDeliveryCompayDisabled,
      );
      dispatch(UpdateUserAction({
        ...user,
        deliveryCompanyDisabled: updateDeliveryCompany,
      }));
    }
  }, [deliveryCompanyList]);

  return (
    <div className={`my-transporters-container ${isMobileDevice ? 'isMobile' : ''}`}>
      <HeaderButtonBack title={`Mis ${capitalizeWords(typeDelivery[user.countryCode])}s`} />
      <div className={`my-transporter-switch-container ${isMobileDevice ? 'isMobile' : ''}`}>
        <div className={`container-title-transporters ${(isMobileDevice) ? 'isMobileDevice' : ''}`}>
          <Typography
            type="paragraph"
            text={`Gestiona tus ${typeDelivery[user.countryCode]}s. Su estado (inactivo/activo) afecta la cobertura de destinos en tus cotizaciones, incluyendo integraciones.`}
            className="paragraph-transporters-settings heeboRegular"
          />
        </div>
        <div className={`switch-container-transporters ${isMobileDevice ? 'isMobile' : ''}`}>
          {deliveryCompanyList?.map((delivery) => {
            const newImage = delivery.image;
            const textDecriptionDeliveryCompany = '';
            return (
              <SwitchStateDeliveryCompany
                key={delivery._id}
                text={delivery.name}
                image={newImage}
                active={!delivery.disabled}
                onChange={(event) => {
                  event.stopPropagation();
                  const listDelivery = deliveryCompanyList.map((deliveryCompany) => {
                    if (deliveryCompany._id === delivery._id && delivery._id !== idDeliveryCompany
                      && listAddressEnabled.length === 0) {
                      return { ...deliveryCompany, disabled: !delivery.disabled };
                    }
                    if (deliveryCompany._id === delivery._id && listAddressEnabled.length !== 0) {
                      return { ...deliveryCompany, disabled: !delivery.disabled };
                    }
                    return deliveryCompany;
                  });
                  setDeliveryCompanyList(listDelivery);
                }}
                textDescription={ReactHtmlParser(textDecriptionDeliveryCompany)}
                deliveryId={delivery._id}
              />
            );
          })}
        </div>
        {
          user.countryCode === '170' && (
            <div className={`switch-container-transporters ${isMobileDevice ? 'isMobile' : ''}`}>
              <PrinFormatSettings />
            </div>
          )
        }
      </div>
    </div>
  );
};

export default MyTransportersSettings;

import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../../../../../components/checkBox';
import { colorTextState, existanceOfGuide } from '../../utils';
import { findObjectInArray } from '../../../../../../utils/filters';
import { humanizationOfTimeDate } from '../../../../../../utils/generals';

import Typography from '../../../../../../components/typography';

import { addThousandPoint, capitalizeWords } from '../../../../../../utils/strings';
import collectionIcon from '../../../../../../assets/images/Icono_pago_contra_entrega.svg';
import errorGuideActive, { guideErrorState } from '../../../common/isAnError';

import './itemListMobile.scss';
import { stateImageClase, textSpanState } from '../../desktopTable/itemListDesktop';

const ItemListMobile = ({
  handleChangeShippingSelecteds,
  downloadShippingGuides,
  shipping,
  shippingSelecteds,
  onChangeBack,
}) => (
  <button
    type="button"
    className="container-item-list-mobile"
    onClick={(e) => {
      if (!(['checkmark ', 'container-checkbox'].includes(e.target.className)) && e.target.type !== 'checkbox') {
        e.stopPropagation();
        onChangeBack();
      }
    }}
  >
    <div className="content-check">
      <Checkbox
        onClick={(e) => {
          e.stopPropagation();
          if (existanceOfGuide(shipping.pdfGuide)) {
            handleChangeShippingSelecteds(shipping._id);
          }
        }}
        disabled={!existanceOfGuide(shipping.pdfGuide)}
        className={`${errorGuideActive(shipping) && 'error-checkbox'}`}
        checked={
          !!findObjectInArray(shipping._id, shippingSelecteds)
        }
        aria-label="button"
      />
    </div>
    <div className="content-shipment-information">
      <div className="content-text">
        <Typography
          type="superTitle"
          className="delivery-company-title"
          text={shipping.deliveryCompanyName}
        />
        <Typography
          type="paragraph"
          className={`guideNumber-title ${(errorGuideActive(shipping)) && 'red'}`}
          text={`Guía ${shipping.guideNumber ?? 'No disponible'}`}
        />
        <Typography
          type="paragraph"
          className="locate-title"
          text={`${capitalizeWords(shipping.locate.originName)} - ${capitalizeWords(shipping.locate.destinyName)}`}
        />
        <Typography
          type="paragraph"
          className="name-title"
          text={`${shipping.receiver.name} ${shipping.receiver.surname}`}
        />
        <Typography
          type="paragraph"
          className={`state__data-shipping ${colorTextState(guideErrorState(shipping))}`}
          text={guideErrorState(shipping)}
        />
      </div>
    </div>
    <div className="content-date-and-download">
      <Typography
        type="paragraph"
        className="date-title"
        text={humanizationOfTimeDate(shipping.confirmationDate, 'general')}
      />
      <div className="icon-hover">
        {shipping.adminTransactionData.collectionValue > 0 && (
          <>
            <img
              src={collectionIcon}
              alt={addThousandPoint(shipping.adminTransactionData.collectionValue)}
            />
            <span>{`Valor recaudo: ${addThousandPoint(shipping.adminTransactionData.collectionValue)}`}</span>
          </>
        )}
      </div>
      {!errorGuideActive(shipping) && (
        <button
          type="button"
          className={`image_button-arrow  ${!existanceOfGuide(shipping.pdfGuide) || shippingSelecteds.length > 0 ? 'disabled' : 'enabled'} ${stateImageClase(shipping)}`}
          onClick={(e) => {
            e.stopPropagation();
            if (existanceOfGuide(shipping.pdfGuide) && shippingSelecteds.length <= 0) {
              downloadShippingGuides(shipping._id);
            }
          }}
          aria-label={textSpanState(shipping)}
        />
      )}
    </div>
  </button>
);

ItemListMobile.propTypes = {
  shipping: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    deliveryCompanyName: PropTypes.string.isRequired,
    guideNumber: PropTypes.string,
    state: PropTypes.string.isRequired,
    pdfGuide: PropTypes.arrayOf(PropTypes.string),
    receiver: PropTypes.shape({
      surname: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    locate: PropTypes.shape({
      originName: PropTypes.string.isRequired,
      destinyName: PropTypes.string.isRequired,
    }).isRequired,
    adminTransactionData: PropTypes.shape({
      collectionValue: PropTypes.number.isRequired,
    }),
    confirmationDate: PropTypes.string.isRequired,
  }).isRequired,
  handleChangeShippingSelecteds: PropTypes.func.isRequired,
  shippingSelecteds: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  downloadShippingGuides: PropTypes.func.isRequired,
  onChangeBack: PropTypes.func.isRequired,
};

export default ItemListMobile;

/* eslint-disable import/no-duplicates */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { pendingCredit } from 'services/cash';
import { businessFlags } from '../../config/config.front.json';

import dataSectionsTitles from '../../constants/dataSectionsTitles';
import { countries, typeDelivery } from '../../constants/general';
import { showDependingOnBusinessFlag } from '../../utils/businessFlag';
import { operationValidate, marketingValidate } from '../../utils/role';
import {
  fullWidth, backgroundWhite, backgroundFigurePosition,
} from '../../utils/generals';
import { redirectToOtherPlatforms } from '../../utils/intercommunication';
import { cashThousandPoint, toCurrencyCopWithOutCOP } from '../../utils/strings';
import { operationsUrl } from '../../constants';

import Dropdown from '../dropDown';
import { validationSteps } from '../../screens/createAccountScreen/createAccount/validations';
import { setCountryAction } from '../../store/actions/country.actions';

import wallet from '../../assets/images/wallet-icon.svg';
import add from '../../assets/images/add-orange-icon.svg';
import star from '../../assets/images/star-blue-icon.svg';
import LogoWhite from '../../assets/images/logo-white.svg';
import LogoOrange from '../../assets/images/logo.svg';
import discount from '../../assets/images/discount-icon.svg';
import colombiaFlag from '../../assets/images/colombia-circle.svg';

import HeaderCards from '../headerCards';

import './header.scss';

const headersClients = [
  { value: '¿Cómo funciona?', blank: 'https://www.mipaquete.com/soluciones-ecommerce' },
  { value: 'Blogística', blank: 'https://mipaquete.com/blog', className: 'hidden-between-1024-1160' },
  { value: 'Puntos mipaquete', path: '/acumula-puntos' },
];

const headersAdmin = (countryCode) => [
  { value: 'Consultar', path: () => ({ urlToRedirect: `${operationsUrl}/buscar-codigo-mp` }) },
  { value: `${typeDelivery[countryCode]}s`, path: () => ({ urlToRedirect: `${operationsUrl}/transportadora` }) },
  { value: 'Tarifas', path: () => ({ urlToRedirect: `${operationsUrl}/reportes-transportadora` }) },
  { value: 'Informes', path: () => ({ urlToRedirect: `${operationsUrl}/informacion-transportadora` }) },
  { value: 'Automatizaciones', path: () => ({ urlToRedirect: `${operationsUrl}/automatizaciones` }) },
];

const headersMarketing = [
  { value: 'Recuperación', path: '/campaña-de-recuperacion' },
];

const selectedHeaders = (user) => {
  if (operationValidate(user)) return headersAdmin(user.countryCode);
  if (marketingValidate(user)) return headersMarketing;
  if (!user) return headersClients;
  return false;
};
const getsection = (user, currentSection) => {
  let section;
  if (user && user.role) {
    section = dataSectionsTitles.internalAutenticated.find((data) => data.url === currentSection) || '';
  } else if (user && !user.role) {
    section = dataSectionsTitles.userAutenticated.find((data) => currentSection.includes(data.url)) || '';
  } else {
    section = dataSectionsTitles.notAutenticated.find((data) => data.url === currentSection) || '';
  }
  return section;
};
const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const businessFlag = useSelector((state) => state.globalData.businessFlags);
  const isMobileDevice = useSelector((state) => state.mediaQuery.isMobileDevice);

  const [currentSection, setCurrentSection] = useState('');
  const [noveltiesCenterVisibility, setNoveltiesCenterVisibility] = useState(false);
  const [country, setCountry] = useState('170');
  const [errorList, setErrorList] = useState([]);
  const [creditAvailable, setCreditAvailable] = useState(0);
  const businessFlagValidation = () => businessFlag?.find((items) => items.name === 'countryCodeValidate');
  const validate = businessFlagValidation();

  const getCredit = async () => {
    if (user) {
      const getPendingCredit = await pendingCredit(user._id)
        .then((response) => response[0]?.total || 0);
      setCreditAvailable(((user?.creditLimitCop || 0) - getPendingCredit));
    }
  };

  useEffect(() => {
    getCredit();
  }, []);

  useEffect(() => {
    setCurrentSection(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const visibilityValidationNoveltiesCenterFunction = () => {
      const visible = showDependingOnBusinessFlag({
        businessFlags, section: 'noveltiesCenter', feature: 'see', userEmail: user.email,
      });
      setNoveltiesCenterVisibility(visible);
    };

    if (user) visibilityValidationNoveltiesCenterFunction();
    if (user) getCredit();
  }, [user, businessFlags]);

  const validateCountry = () => validate?.enabled && (location.pathname === '/cotizacion/quoteShipping' || location.pathname === '/cotizacion');

  const tooltipUser = user?.points === 0 ? 'Ver puntos MIPaquete' : 'Redimir puntos';

  const logoClass = getsection(user, currentSection).length <= 0 ? 'visible' : '';
  const flagValidate = () => ({ flag: colombiaFlag, alfa2code: 'CO', name: 'Colombia' });
  return (
    isMobileDevice ? (
      <div
        id="header"
        className={`mobile-mode ${location.pathname === '/cotizacion' ? 'hidden' : ''} `}
      >
        {validateCountry()
          ? (
            <Dropdown
              value={country}
              onChange={(value) => {
                setCountry(value);
                validationSteps({
                  errorList, setErrorList, country: value, countries,
                });
                dispatch(setCountryAction(value));
              }}
              selectText="Selecciona una país"
              mainImg={flagValidate.flag}
              dropDownText={flagValidate.alfa2code}
              tooltip={flagValidate.name}
              options={countries}
            />
          ) : (
            <>
              <img
                id="logo"
                src={LogoWhite}
                alt="logo"
                className={getsection(user, currentSection).label ? 'visible' : ''}
                onClick={() => navigate('/cotizacion')}
              />
              <div
                id="content-section"
                className={logoClass}
              >
                <h1>{getsection(user, currentSection).label}</h1>
              </div>
            </>
          )}
      </div>
    ) : (
      <div id="header" className="desktop-mode">
        <div id="background-logo" className="background-col">
          <img
            id="logo"
            src={(
              fullWidth(location.pathname)
              || (backgroundWhite(location.pathname) && LogoOrange)
              || backgroundFigurePosition(location.pathname) !== 'top-left'
              || LogoWhite
            )}
            onClick={() => navigate('/')}
            alt="logo"
          />
        </div>
        <div className="container">
          <ul>
            {selectedHeaders(user) !== false && selectedHeaders(user).map((header) => (
              ((header.value === 'Novedades' && noveltiesCenterVisibility)
                || (header.value !== 'Novedades')) && (
                <li
                  key={header.value}
                  className={header.className}
                  onClick={() => {
                    if (header.path) {
                      if (typeof header.path === 'function') {
                        redirectToOtherPlatforms(header.path());
                      } else {
                        navigate(header.path);
                      }
                    } else {
                      window.open(header.blank, '_blank');
                    }
                  }}
                >
                  {header.value}
                </li>
              )
            ))}
            {selectedHeaders(user) === false && (
              <div className="cards">
                <HeaderCards
                  iconLeft={add}
                  colorData="cash"
                  iconLeftSize="orange"
                  textRight={user.currencyToPay}
                  tooltip="Recargar saldo para envíos"
                  action={() => navigate('/recargar-saldo')}
                  data={toCurrencyCopWithOutCOP(user, user.cash)}
                />
                {user?.creditPayment && (
                  <HeaderCards
                    iconLeft={wallet}
                    colorData="cash"
                    iconLeftSize="icon-default"
                    textRight={user.currencyToPay}
                    tooltip="Crédito disponible"
                    data={`$${cashThousandPoint(parseInt(creditAvailable ?? 0, 10))}`}
                  />
                )}
                <div>
                  {user?.volumeDiscountNegotiation?.enabled ? (
                    <HeaderCards
                      iconLeft={discount}
                      colorData="default"
                      iconLeftSize="icon-default"
                      tooltip="Dctos. por volumen activados"
                      data="Descuentos"
                    />
                  ) : (
                    <HeaderCards
                      iconLeft={star}
                      textRight="Pts"
                      colorData="default"
                      iconLeftSize="blue"
                      tooltip={tooltipUser}
                      data={`${cashThousandPoint(parseInt(user?.points ?? 0, 10))}`}
                      action={() => {
                        navigate('/recargar-saldo');

                        setTimeout(() => {
                          navigate('/acumula-puntos');
                        }, 5);
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </ul>
          {validate?.enabled && location.pathname === '/cotizacion' && !user && (
            <Dropdown
              value={country}
              onChange={(value) => {
                setCountry(value);
                validationSteps({
                  errorList, setErrorList, country: value, countries,
                });
                dispatch(setCountryAction(value));
              }}
              selectText="Selecciona una país"
              mainImg={flagValidate.flag}
              dropDownText={flagValidate.alfa2code}
              tooltip={flagValidate.name}
              options={countries}
            />
          )}
        </div>
      </div>
    )
  );
};

export default Header;

/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { compose } from 'redux';
import { loadKustomerChat } from 'utils/loadChatKustomer';

import { showMenuAction, closeMenuAction } from '../../store/actions/menu.actions';
import { LogoutAction } from '../../store/actions/auth.actions';
import { setCountryAction } from '../../store/actions/country.actions';

import { capitalizeWords, userName } from '../../utils/strings';
import { hiddenRigthMenu } from '../../utils/generals';
import { logout } from '../../utils/auth';
import { showDependingOnBusinessFlag } from '../../utils/businessFlag';
import { existRole, myDiscountsValidate } from '../../utils/role';
import { useValidationCountry, useFetchIpData } from '../../utils/validationCountry';
import { helpCenterUrl, integrateStoreUrl } from '../../constants/urls';
import { typeDelivery } from '../../constants/general';

import Button from '../button';
import UserLevel from '../userLevel';
import Typography from '../typography';
import NotificationsIcon from '../notificationsIcon';

import IconHome from '../../assets/images/home.svg';
import ArrowTop from '../../assets/images/arrow-top.svg';
import IconMenu from '../../assets/images/menuIcons/menu.svg';
import WhiteLogo from '../../assets/images/menuIcons/white-logo.svg';
import LogoMEX from '../../assets/images/Logo-clicOH.svg';
import whatsapp from '../../assets/images/whatsapp-help.svg';

import './rigthSideMenu.scss';
import './avatarIcon.scss';

const IconUser = ({ name, countryCode }) => (
  <div className={countryCode === '484' ? 'avatarIconMX' : 'avatarIcon'}>
    <h1>{name?.charAt(0).toUpperCase()}</h1>
  </div>
);

const ToggleMenuDesktopUser = ({
  isVisible, logoutFunction, historyProfile, notificacionesDestinatarios, myTransportersSettings,
  setshowMenuTogge, user, historyPointsModule, businessFlags, bargainings, myDiscounts,
  myAddressesSettings,
}) => (
  <div
    className={isVisible ? (
      `toggle-modal ${showDependingOnBusinessFlag({ businessFlags, section: 'pointsModule' }) ? 'with-discount-module' : ''}`
    ) : (
      'toggle-modal toggle-modal-none'
    )}
  >
    <div className={`content-toggle-menu ${isVisible ? 'visible' : ''}`} onClick={setshowMenuTogge}>
      <div className="content-body-tooggle-menu">
        {showDependingOnBusinessFlag({ businessFlags, section: 'pointsModule' }) && (
          <div className="counter-mile-rigth-menu-desktop">
            {!myDiscountsValidate(user) && (
              <UserLevel
                smallVersion
                userId={user?._id}
                onClick={() => historyPointsModule()}
              />
            )}
          </div>
        )}
        <div className="menu-option" onClick={() => historyProfile()}>
          <Typography
            type="superTitle-italic"
            text="Mi perfil"
            className="profile"
          />
        </div>
        {myDiscountsValidate(user) && (
          <div className="menu-option" onClick={() => myDiscounts()}>
            <Typography
              type="superTitle-italic"
              text="Mis descuentos"
              className="profile"
            />
          </div>
        )}
        {user.clientType === 'SaaS' && (
          <div className="menu-option" onClick={() => bargainings()}>
            <Typography
              type="superTitle-italic"
              text="Mis negociaciones"
              className="profile"
            />
          </div>
        )}
        {showDependingOnBusinessFlag({ businessFlags, section: 'notificationsSettings' })
          && !existRole(user) && (
            <div className="menu-option" onClick={() => notificacionesDestinatarios()}>
              <Typography
                type="superTitle-italic"
                text="Notificaciones a destinatarios"
                className="profile"
              />
            </div>
        )}
        {showDependingOnBusinessFlag({ businessFlags, section: 'myTransportersSettings' })
          && !existRole(user) && (
            <div className="menu-option" onClick={() => myTransportersSettings()}>
              <Typography
                type="superTitle-italic"
                text={`Mis ${capitalizeWords(typeDelivery[user.countryCode])}s`}
                className="profile"
              />
            </div>
        )}
        {showDependingOnBusinessFlag({ businessFlags, section: 'myDirectionsSettings' })
          && !existRole(user) && user.countryCode !== '484' && (
            <div className="menu-option" onClick={() => myAddressesSettings()}>
              <Typography
                type="superTitle-italic"
                text="Mis direcciones"
                className="profile"
              />
            </div>
        )}
      </div>
      <hr />
    </div>
    <div className="content-logout-toggle-menu" onClick={() => logoutFunction()}>
      <Typography
        type="superTitle-italic"
        text="Cerrar sesión"
        className="logout"
      />
    </div>
  </div>
);

const ListItemMobile = ({
  user, showMenu, closeMenu, logoutFunction, businessFlags,
}) => {
  const navigate = useNavigate();
  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);

  const [showHelp, setShowHelp] = useState(false);

  const handleOpenLink = (url) => {
    window.open(url, 'WindowName', 'noopener');
  };
  const handleChatClick = () => {
    loadKustomerChat(user, null, true);
  };

  return (
    <div
      id="content__menu"
      className={showMenu ? 'menu-displayed-mobile' : ''}
    >
      <div className="menu">
        {user ? (
          <>
            {showDependingOnBusinessFlag({ businessFlags, section: 'pointsModule' }) && (
              <div className="counter-mile-rigth-menu">
                {!myDiscountsValidate(user) && (
                  <UserLevel
                    smallVersion
                    userId={user?._id}
                    onClick={() => {
                      closeMenu();
                      navigate('/acumula-puntos');
                    }}
                  />
                )}
              </div>
            )}
            <div
              className="item-menu"
              onClick={() => { closeMenu(); navigate('/perfil'); }}
            >
              <span>Mi perfil</span>
            </div>
            {myDiscountsValidate(user) && (
              <div
                className="item-menu"
                onClick={() => {
                  closeMenu();
                  navigate('/mis-descuentos');
                }}
              >
                <span>Mis descuentos</span>
              </div>
            )}
            {user.clientType === 'SaaS' && (
              <div
                className="item-menu"
                onClick={() => { closeMenu(); navigate('/mis-negociaciones'); }}
              >
                <span>Mis negociaciones</span>
              </div>
            )}
            <div
              className="item-menu"
              onClick={() => { closeMenu(); navigate('/notificaciones-a-destinatarios'); }}
            >
              <span>Notificaciones a destinatarios</span>
            </div>
            {showDependingOnBusinessFlag({ businessFlags, section: 'myTransportersSettings' })
              && !existRole(user) && (
                <div
                  className="item-menu"
                  onClick={() => { closeMenu(); navigate('/mis-transportadoras'); }}
                >
                  <span>
                    Mis
                    {capitalizeWords(typeDelivery[user.countryCode])}
                    s
                  </span>
                </div>
            )}
            {showDependingOnBusinessFlag({ businessFlags, section: 'myDirectionsSettings' })
              && !existRole(user) && user.countryCode !== '484' && (
                <button
                  type="button"
                  className="item-menu"
                  onClick={() => { closeMenu(); navigate('/mis-direcciones'); }}
                >
                  <span>Mis direcciones</span>
                </button>
            )}
          </>
        ) : (
          <div className="item-menu no-hover">
            <div className="buttons-for-login-or-register">
              <Button
                text="Ingresa"
                type="secondary"
                className="border-none"
                onClick={() => { closeMenu(); navigate('/ingreso'); }}
              />
              <Button
                text="Regístrate"
                type={isCountryValid ? 'primary-mx' : 'primary'}
                onClick={() => { closeMenu(); navigate('/registro'); }}
              />
            </div>
          </div>
        )}
        <hr />
        {user ? (
          <button
            type="button"
            className="content-help-chat"
            id="startChatButton"
            onClick={handleChatClick}
          >
            <img alt="arrow-direct" src={ArrowTop} />
            <Typography text="MiPaquete Chat" className={showHelp ? 'help-text-on' : 'help-text-off'} type="superTitle-italic" />
          </button>
        ) : (
          <button
            type="button"
            className="content-help"
            id="goToWhatsapp"
            onClick={() => window.open('https://wa.me/+5714924696', '_self')}
          >
            <img className="imgBtnWhatsapp" src={whatsapp} alt={whatsapp} />
            <Typography text="Habla con un experto comercial" className={showHelp ? 'help-text-on-wa' : 'help-text-off-wa'} type="superTitle-italic" />
          </button>
        )}
        <button
          type="button"
          className="content-help"
          onClick={() => setShowHelp(!showHelp)}
        >
          <div className={showHelp ? 'img-off' : 'img-on'} />
          <Typography text="Ayuda" className={showHelp ? 'help-text-on' : 'help-text-off'} type="superTitle-italic" />
          <div className={showHelp ? 'img-up' : 'img-down'} />
          <span className="visually-hidden">Mostrar/Ocultar ayuda</span>
        </button>
        {showHelp && (
          <button
            type="button"
            onClick={() => handleOpenLink(helpCenterUrl)}
            className="help-links"
          >
            <button
              type="button"
              onClick={() => handleOpenLink(helpCenterUrl)}
              className="help-links-contact"
            >
              <Typography text="Ir al Centro de Ayuda " className="link-text link-text-contact-1" type="superTitle-italic" />
              <img alt="arrow-direct" src={ArrowTop} />
            </button>
            {user?.countryCode !== '484' && (
              <button
                type="button"
                onClick={() => handleOpenLink(integrateStoreUrl)}
                className="help-links-contact"
              >
                <Typography text="Integra tu tienda " className="link-text link-text-contact-2" type="superTitle-italic" />
                <img alt="arrow-direct" src={ArrowTop} />
              </button>
            )}
          </button>
        )}
      </div>

      {user && (
        <button
          type="button"
          className="logout"
          onClick={() => {
            logoutFunction();
            closeMenu();
            navigate('/');
          }}
        >
          <hr />
          <span>Cerrar sesión</span>
        </button>
      )}
    </div>
  );
};

const MobileMode = ({
  showMenu, history, isMobileDevice, closeMenuFunction,
  showMenuFunction, showMenuTogge, setshowMenuTogge,
  user, logoutFunctionOwner, businessFlags,
}) => {
  const ipData = useFetchIpData();
  const isCountryValid = useValidationCountry(ipData.country);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      {!showMenu
        && (location.pathname !== '/' && !hiddenRigthMenu(location.pathname) ? (
          <div className="icon-home">
            <img
              className="icon-home__mobile"
              src={IconHome}
              alt=""
              onClick={() => navigate('/')}
            />
          </div>
        ) : user && (
          <NotificationsIcon
            className="notificationsIconLikeHome"
            selected={showMenuTogge}
            positionOfNumbers="top"
            isMobileDevice={isMobileDevice}
          />
        ))}

      <button
        type="button"
        id="background__menu"
        className={[
          showMenu ? 'menu-displayed-mobile' : '',
          hiddenRigthMenu(location.pathname) && isMobileDevice ? 'hidden' : '',
        ].join(' ')}
        onClick={() => showMenu && closeMenuFunction()}
        aria-label="cerrar menu"
      />

      <button
        type="button"
        id="button__menu"
        onClick={() => (showMenu ? closeMenuFunction() : isMobileDevice && showMenuFunction())}
        className={[
          showMenu ? 'menu-displayed-mobile' : '',
          !isMobileDevice ? 'desktop-mode' : '',
          !isMobileDevice && showMenuTogge ? 'menu-displayed-desktop' : '',
          hiddenRigthMenu(location.pathname) && isMobileDevice ? 'hidden' : '',
        ].join(' ')}
      >
        <div
          id="background__button__menu"
          className={isCountryValid ? 'mx' : ''}
        />
        <button
          type="button"
          id="content__button__menu"
          onClick={() => setshowMenuTogge(!showMenuTogge)}
        >
          <div className="container-user-data__content__button__menu">
            {user ? (
              <>
                <span>{userName(user.name, user.surname)}</span>
                {user.image && (
                  <div id="user-image__content__button__menu">
                    <img src={user.image} alt="" />
                  </div>
                )}
                {!user.image && (
                  <IconUser name={user.name} countryCode={user.countryCode} />
                )}
              </>
            ) : (
              <>
                {isMobileDevice && (
                  <img
                    className={`icon__user-data__content__button__menu ${showMenu ? '' : 'visible'
                    }`}
                    src={IconMenu}
                    alt=""
                  />
                )}
                <img
                  className={`white-logo ${showMenu ? 'visible' : ''}`}
                  src={isCountryValid ? LogoMEX : WhiteLogo}
                  alt=""
                />
              </>
            )}
          </div>
        </button>
      </button>

      <ListItemMobile
        user={user}
        showMenu={showMenu}
        closeMenu={closeMenuFunction}
        history={history}
        logoutFunction={logoutFunctionOwner}
        businessFlags={businessFlags}
      />
    </>
  );
};

const DesktopMode = ({
  showMenu, isMobileDevice, closeMenuFunction,
  showMenuFunction, showMenuTogge, setshowMenuTogge,
  user, logoutFunctionOwner, historyProfile, notificacionesDestinatarios,
  myTransportersSettings, historyPointsModule, businessFlags, bargainings, myDiscounts,
  myAddressesSettings, countryCode,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isCountryValid = useValidationCountry(countryCode);

  const logoutChatKustomer = () => {
    // Logout a customer and run a callback after logout
    logoutFunctionOwner();
    window.Kustomer.stop();
  };
  return (
    <>
      <button
        type="button"
        id="background__menu"
        className={showMenu ? 'menu-displayed-mobile' : ''}
        onClick={() => { if (showMenu) closeMenuFunction(); }}
        aria-label="menu"
      />

      <button
        type="button"
        id="button__menu"
        onClick={() => (
          showMenu
            ? closeMenuFunction()
            : !isMobileDevice && user && showMenuFunction()
        )}
        className={[
          showMenu ? 'menu-displayed-mobile' : '',
          !isMobileDevice ? 'desktop-mode' : '',
          !isMobileDevice && showMenuTogge ? 'menu-displayed-desktop' : '',
          hiddenRigthMenu(location.pathname) && isMobileDevice ? 'hidden' : '',
          isCountryValid ? 'mx' : '',
        ].join(' ')}
      >
        <div id="background__button__menu" className={isCountryValid ? 'mx' : ''} onClick={() => { setshowMenuTogge(!showMenuTogge); }} />
        <button type="button" id="content__button__menu" onClick={() => { setshowMenuTogge(!showMenuTogge); }}>
          <div className="container-user-data__content__button__menu">
            {user ? (
              <>
                <NotificationsIcon selected={showMenuTogge} isMobileDevice={isMobileDevice} />
                <span>{userName(user.name, user.surname)}</span>
                {user.image && (
                  <div id="user-image__content__button__menu">
                    <img src={user.image} alt="" />
                  </div>
                )}
                {!user.image && <IconUser name={user.name} countryCode={user.countryCode} />}
              </>
            ) : (
              <>
                {location.pathname !== '/ingreso' && (
                  <Button
                    text="Ingresa"
                    type="secondary"
                    className="buttonToLoginOrRegister"
                    onClick={() => navigate('/ingreso')}
                  />
                )}
                {location.pathname !== '/registro' && (
                  <Button
                    text="Regístrate"
                    type="tertiary"
                    className="buttonToLoginOrRegister"
                    onClick={() => navigate('/registro')}
                  />
                )}
              </>
            )}
          </div>
        </button>
      </button>

      {user && (
        <ToggleMenuDesktopUser
          isVisible={showMenu}
          logoutFunction={() => logoutChatKustomer()}
          historyProfile={historyProfile}
          myDiscounts={myDiscounts}
          historyPointsModule={historyPointsModule}
          user={user}
          setshowMenuTogge={() => setshowMenuTogge(false)}
          businessFlags={businessFlags}
          bargainings={bargainings}
          notificacionesDestinatarios={notificacionesDestinatarios}
          myTransportersSettings={myTransportersSettings}
          myAddressesSettings={myAddressesSettings}
        />
      )}
    </>
  );
};

const RigthSideMenu = ({
  showMenu, businessFlags,
  isMobileDevice, user, countryCode,
}) => {
  const navigate = useNavigate();
  const history = {
    replace: (url) => (navigate(url, { replace: true })),
    push: (url) => (navigate(url)),
    goBack: () => (navigate(-1)),
  };

  const dispatch = useDispatch();

  const closeMenuFunction = () => (dispatch(closeMenuAction()));
  const showMenuFunction = () => (dispatch(showMenuAction()));
  const logoutFunction = () => (dispatch(LogoutAction()));

  const [showMenuTogge, setshowMenuTogge] = useState(false);

  const redirect = (path) => navigate(path);
  const closeMenu = () => { setshowMenuTogge(false); closeMenuFunction(); };

  const logoutFunctionOwner = () => {
    logout();
    logoutFunction();
    history.replace('/');
    closeMenu();
  };

  useEffect(() => {
    if (!user) {
      dispatch(setCountryAction('170'));
    }
  }, [user]);
  const historyProfile = () => {
    redirect('/perfil');
    closeMenu();
  };

  const myDiscounts = () => {
    redirect('/mis-descuentos');
    closeMenu();
  };

  const bargainings = () => {
    redirect('/mis-negociaciones');
    closeMenu();
  };

  const historyPointsModule = () => {
    redirect('/acumula-puntos');
    closeMenu();
  };

  const notificacionesDestinatarios = () => {
    redirect('/notificaciones-a-destinatarios');
    closeMenu();
  };

  const myTransportersSettings = () => {
    redirect('/mis-transportadoras');
    closeMenu();
  };

  const myAddressesSettings = () => {
    redirect('/mis-direcciones');
    closeMenu();
  };

  return (
    <>
      {
        isMobileDevice ? (
          <MobileMode
            showMenu={showMenu}
            history={history}
            isMobileDevice={isMobileDevice}
            closeMenuFunction={closeMenuFunction}
            showMenuFunction={showMenuFunction}
            showMenuTogge={showMenuTogge}
            setshowMenuTogge={setshowMenuTogge}
            user={user}
            logoutFunction={logoutFunction}
            logoutFunctionOwner={logoutFunctionOwner}
            historyProfile={historyProfile}
            myDiscounts={myDiscounts}
            businessFlags={businessFlags}
            bargainings={bargainings}
            notificacionesDestinatarios={notificacionesDestinatarios}
            myTransportersSettings={myTransportersSettings}
            myAddressesSettings={myAddressesSettings}
          />
        ) : (
          <DesktopMode
            showMenu={showMenu}
            history={history}
            isMobileDevice={isMobileDevice}
            closeMenuFunction={closeMenu}
            showMenuFunction={showMenuFunction}
            showMenuTogge={showMenuTogge}
            setshowMenuTogge={setshowMenuTogge}
            user={user}
            logoutFunction={logoutFunction}
            logoutFunctionOwner={logoutFunctionOwner}
            historyProfile={historyProfile}
            myDiscounts={myDiscounts}
            historyPointsModule={historyPointsModule}
            businessFlags={businessFlags}
            bargainings={bargainings}
            notificacionesDestinatarios={notificacionesDestinatarios}
            myTransportersSettings={myTransportersSettings}
            myAddressesSettings={myAddressesSettings}
            countryCode={countryCode}
          />
        )
      }
    </>
  );
};

RigthSideMenu.propTypes = {
  user: PropTypes.shape({
    countryCode: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
  }),
  isMobileDevice: PropTypes.bool.isRequired,
  showMenu: PropTypes.bool.isRequired,
  businessFlags: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  countryCode: PropTypes.string,
};

RigthSideMenu.defaultProps = {
  user: null,
  countryCode: null,
};

MobileMode.propTypes = {
  user: PropTypes.shape({
    countryCode: PropTypes.string,
    image: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

IconUser.propTypes = {
  name: PropTypes.string.isRequired,
  countryCode: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  showMenu: state.menu.show,
  isMobileDevice: state.mediaQuery.isMobileDevice,
  user: state.auth.user,
  businessFlags: state.globalData.businessFlags || [],
});

export default compose(
  connect(mapStateToProps),
)(RigthSideMenu);
